
<mat-form-field class="width100procent rahmen">
    <mat-label>{{title$ | async}}</mat-label>
    <input type="file" class="hidden" #fileInput (change)="onFileSelected($event)" />
    <input matInput #inputElement [ngModel]="value$ | async"
        readonly />
    <button *ngIf="inputElement.value && withClearButton" matSuffix mat-icon-button aria-label="Clear"
        (click)="clearValue()">
        <mat-icon>close</mat-icon>
    </button>
    <button matSuffix mat-icon-button aria-label="File-Dialog"
        (click)="fileInput.click()">
        <mat-icon>folder_open</mat-icon>
    </button>
</mat-form-field>
