import { AfterViewInit, Component, ComponentRef, OnDestroy, OnInit, QueryList, ViewChildren, ViewContainerRef } from '@angular/core';
import { DynamicUiService } from '../../services/dynamic-ui.service'
import { ActivatedRoute, EventType, Router } from '@angular/router';
import { DynamicBaseClass } from '../../controls/dynamic-base-class/dynamic-base-class.component';
import { UiDefinition } from '../../models/ui-definition.models';
import { DynamicFacade } from '../../store/dynamic.facade';
import { AppFacade } from 'src/app/app/store/app.facade';
import { Observable, Subscription, filter, first, map } from 'rxjs';
import { AuthFacade } from 'src/app/auth/store/auth.facade';
import { Url } from 'src/app/app/models/url';
import { AppConstants } from 'src/app/app/helpers/app-constants';

@Component({
    selector: 'app-dynamic',
    templateUrl: './dynamic.component.html',
    styleUrls: ['./dynamic.component.scss']
})
export class DynamicComponent implements OnInit, OnDestroy, AfterViewInit {

    public uiDefinition: UiDefinition;

    viewSubscription: Subscription;
    routerSubscription: Subscription;
    router$: Observable<any>;
    urls$: Observable<Url[]>;
    componentRef: ComponentRef<DynamicBaseClass>;

    @ViewChildren('children', { read: ViewContainerRef }) viewChildrenRef: QueryList<ViewContainerRef>;

    constructor(
        private appFacade: AppFacade,
        private authFacade: AuthFacade,
        private dynamicFacade: DynamicFacade,
        private dynamicUiServices: DynamicUiService,
        private router: Router,
        private activatedRoute: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.authFacade.urls$.pipe(
            filter((urls: Url[]) => urls.length > 0),
            first(),
            map((urls: Url[]) => urls)
        ).subscribe(
            () => {
                //console.log("Init DynamicComponents from auth store");
                this.InitDynamicComponent();
            }
        );
    }

    ngAfterViewInit(): void {
        this.routerSubscription = this.router.events.pipe(
            filter(event => event.type == EventType.NavigationEnd),
            map((event) => event)
        ).subscribe(
            () => {
                //console.log("Init DynamicComponents from router");
                this.InitDynamicComponent();
            }
        );

    }

    ngOnDestroy(): void {
        this.routerSubscription.unsubscribe();
    }

    InitDynamicComponent(): void {
        console.log(this.router.url)
        this.uiDefinition = this.dynamicUiServices.GetUiDefinitionFromUrl(this.router.url);
        console.log("Init Dynamic View - clear store : ", this.uiDefinition.name);
        this.dynamicUiServices.InitService();
        this.dynamicFacade.InitLocalStore(this.uiDefinition);
        this.appFacade.SetTitle(this.uiDefinition?.title);
        this.dynamicUiServices.SetValue(AppConstants.dataStoreUrlQueryParameters, this.activatedRoute.snapshot.queryParams);
        this.InitView();
    }

    InitView(): void {
        if (this.uiDefinition?.definition) {
            setTimeout(() => {
                this.viewChildrenRef.map((vcr: ViewContainerRef, index: number) => {
                    vcr.clear();
                    const component = this.dynamicUiServices.GetComponent(this.uiDefinition.definition[index])?.component;
                    if (component) {
                        this.componentRef = vcr.createComponent(component);
                        this.componentRef.instance.children = this.uiDefinition.definition[index].children;
                        this.componentRef.instance.config = this.uiDefinition.definition[index].config;
                    }
                })
            });
        }
    }

}
