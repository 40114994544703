import {
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
    Inject,
    Injector,
    AfterViewInit
} from '@angular/core';
import { DynamicBaseClass } from '../dynamic-base-class/dynamic-base-class.component';
import { Observable } from 'rxjs';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { DatePipe } from '@angular/common';
import { ObjectToJsonPipe } from '../../../shared/pipes/object-to-json.pipe'

@Component({
    selector: 'app-dynamic-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
})
export class InputComponent
    extends DynamicBaseClass
    implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('inputElement', { read: ElementRef, static: false }) inputElement: ElementRef<HTMLInputElement | HTMLTextAreaElement>;

    public value$: Observable<string>;
    public title$: Observable<string>;
    readonly: boolean = false;
    withClearButton: boolean = false;
    type: string = 'text';
    passwordHide: boolean = true;
    textAreaRows: number = 2;
    formGroupName: string;
    isRequired: boolean = false;

    constructor(
        injector: Injector,
        @Inject(MAT_DATE_LOCALE) private _locale: string,
        private _adapter: DateAdapter<any>,
        private _datepipe: DatePipe
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.value$ = this.GetValueFromConfig();

        this.title$ = this.GetTitleFromConfig();
        if (this.config) {
            this.readonly = this.config.readonly ?? false;
            this.withClearButton = this.config.withClearButton ?? false;
            this.type = this.config.type ?? 'text';
            this.textAreaRows = this.config.textAreaRows ? Number(this.config.textAreaRows) : 2;
            this.formGroupName = this.config.formGroupName;
            this.isRequired = this.config.isRequired ?? false;
        }
        this._locale = navigator.language;;
        this._adapter.setLocale(this._locale);
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    ngAfterViewInit(): void {
        if (this.formGroupName) {
            this.dynamicUiServices.AddControlToFormControls(this.formGroupName, this.inputElement);
        }
    }

    onModelChange(value: string) {
        this.SetValueFromConfig(value);
    }

    onModelChangeDate(dateValue: Date) {
        this.SetValueFromConfig(this._datepipe.transform(dateValue, 'yyyy-MM-ddT00:00:00'));
    }

    clearValue() {
        this.inputElement.nativeElement.value = "";
        this.SetValueFromConfig('');
        this.inputElement.nativeElement.dispatchEvent(new Event('blur'));
    }
}
