
export class SendMailRequest {
  //templateName: string;
  sender: string;
  recipients: string[];
  subject: string;
  mailbody: string;
  viaSMTP: boolean;

  constructor(sender: string =  "", recipients: string[], subject: string = "", mailbody: string = "", viaSMTP: string = "0") {
    this.sender = sender;
    this.recipients = recipients;
    this.subject = subject;
    this.mailbody = mailbody;
    if (viaSMTP == "1") { this.viaSMTP = true} else { this.viaSMTP = false};
  }
}
