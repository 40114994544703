import {
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
    Injector
} from '@angular/core';
import { DynamicBaseClass } from '../dynamic-base-class/dynamic-base-class.component';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-dynamic-file-selector',
    templateUrl: './file-selector.component.html',
    styleUrls: ['./file-selector.component.scss'],
})
export class FileSelectorComponent
    extends DynamicBaseClass
    implements OnInit, OnDestroy {

    @ViewChild('inputElement', { read: ElementRef, static: false }) inputElement: ElementRef;

    public value$: Observable<string>;
    public title$: Observable<string>;
    withClearButton: boolean = false;
    
    constructor(
        injector: Injector
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.title$ = this.GetTitleFromConfig();
        if (this.config) {
            this.withClearButton = this.config.withClearButton ?? false;
        }
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    clearValue() {
        this.inputElement.nativeElement.value = "";
        this.SetValueFromConfig('');
    }

    async onFileSelected(event: any) {
        const file: File = event.target.files[0];
        this.inputElement.nativeElement.value = file.name
        this.SetValueFromConfig(await file.text());
    }
}
