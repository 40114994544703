import { Component, Input } from '@angular/core';
import { Observable, of } from 'rxjs';
import { NotifierService } from '../../services/notifier.service';
import { HeaderMenu } from '../../models/header-menu.models';
import { AuthFacade } from 'src/app/auth/store/auth.facade';
import { HeaderMenu2 } from '../../models/header-menu2.models';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss']
})
export class HeaderMenuComponent {

  @Input() menuItem: HeaderMenu2;

  headerMenu2$ = this.authFacade.headerMenu2$;
  loggedInUser$ = this.authFacade.loggedInUser$;
  isLoggedIn$ = this.authFacade.isLoggedIn$;

  constructor(private authFacade: AuthFacade) {
  }

  GetChilds(menuItem: HeaderMenu2) {
    //console.log("*** 1:", menuItem);
    if (menuItem.ChildCount > 0 && menuItem.Id) {
      var a = this.authFacade.selectHeaderMenu2FromParentId(menuItem.Id);
      //a.forEach(c => {console.log("+++ 1:", c)});
      return a;
    }
    else
      return of([]);
  }

  onLogout() {
    this.authFacade.logout();
  }
}
