import { AfterViewInit, Component, ComponentRef, Injector, OnDestroy, OnInit, QueryList, ViewChildren, ViewContainerRef } from '@angular/core';
import { DynamicBaseClass } from '../dynamic-base-class/dynamic-base-class.component';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent extends DynamicBaseClass implements OnInit, OnDestroy, AfterViewInit {

  private componentRef: ComponentRef<DynamicBaseClass>;
  @ViewChildren('children', { read: ViewContainerRef }) viewChildrenRef: QueryList<ViewContainerRef>;

  formGroupName: string;
  onSubmitExecuteActionName: string;

  constructor(
    injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    if (this.config) {
      this.formGroupName = this.config.formGroupName;
      this.onSubmitExecuteActionName = this.config.onSubmitExecuteActionName;
      super.GlobalConfig();
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngAfterViewInit(): void {
    if (this.children) {
      setTimeout(() => {
        this.AddComponentsFromChildren(this.viewChildrenRef, this.componentRef);
      });
    }
  }

  onSubmit() {
    this.dynamicFacade.ExecuteAction(this.onSubmitExecuteActionName);
  }

}

