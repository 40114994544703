import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { DynamicBaseClass } from '../dynamic-base-class/dynamic-base-class.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-dynamic-submit-button',
  templateUrl: './submit-button.component.html',
  styleUrls: ['./submit-button.component.scss']
})
export class SubmitButtonComponent extends DynamicBaseClass implements OnInit, OnDestroy {

  public value$: Observable<string>;
  public title$: Observable<string>;
  color$: Observable<string>;

  formGroupName: string;

  constructor(
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.title$ = this.GetTitleFromConfig();
    this.color$ = this.GetColorFromConfig();
    if (this.config) {
      this.formGroupName = this.config.formGroupName;
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public isFormValid(): boolean {
    return this.formGroupName ? this.dynamicUiServices.IsFormValid(this.formGroupName) : true;
  }

}

