import { ElementRef, Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, first, map, mergeMap, Subject } from 'rxjs';
import { DynamicFacade } from '../store/dynamic.facade';
import { AuthFacade } from 'src/app/auth/store/auth.facade';
import { Url } from 'src/app/app/models/url';
import { UiDefinition } from '../models/ui-definition.models';
import { UiComponent } from '../models/ui-component.models';
import { LabelComponent } from '../controls/label/label.component';
import { RowComponent } from '../controls/row/row.component';
import { ColumnComponent } from '../controls/column/column.component';
import { LabelWithTitleComponent } from '../controls/label-with-title/label-with-title.component';
import { TabComponent } from '../controls/tab/tab.component';
import { LinkComponent } from '../controls/link/link.component';
import { InputComponent } from '../controls/input/input.component';
import { UiAction } from '../models/ui-action.models';
import { DropDownComponent } from '../controls/drop-down/drop-down.component';
import { ExecuteSpRequest } from '../models/execute-sp-request.models';
import { SpRequestBodyInputParameter } from '../models/sp-request-body-input-parameter.models';
import { TableComponent } from '../controls/table/table.component';
import { ButtonComponent } from '../controls/button/button.component';
import { ConvertStoreDataToElements } from 'src/app/shared/helpers/store-data-helper';
import { SpInputParameter2 } from '../models/sp-input-parameter2.models';
import { ExecuteSpResponse } from 'src/app/dynamic-ui/models/execute-sp-response.models';
import { CardComponent } from 'src/app/dynamic-ui/controls/card/card.component';
import { GridComponent } from 'src/app/dynamic-ui/controls/grid/grid.component';
import { GridItemComponent } from 'src/app/dynamic-ui/controls/grid-item/grid-item.component';
import { TitleComponent } from 'src/app/dynamic-ui/controls/title/title.component';
import { ExpansionPanelComponent } from '../controls/expansion-panel/expansion-panel.component';
import { AccordionComponent } from '../controls/accordion/accordion.component';
import { isEqual } from 'lodash-es';
import { DividerComponent } from '../controls/divider/divider.component';
import { Router, UrlSerializer } from '@angular/router';
import { TreeComponent } from '../controls/tree/tree.component';
import { GeneratePDFRequest } from '../models/generate-pdf-request.models';
import { GeneratePDFResponse } from '../models/generate-pdf-response.models';
import { SendMailRequest } from '../models/send-mail-request.models';
import { SendMailResponse } from '../models/send-mail-response.models';
import { PdfViewerComponent } from '../controls/pdf-viewer/pdf-viewer.component';
import { CheckboxComponent } from '../controls/checkbox/checkbox.component';
import { FileSelectorComponent } from '../controls/file-selector/file-selector.component';
import { FormComponent } from 'src/app/dynamic-ui/controls/form/form.component';
import { SubmitButtonComponent } from '../controls/submit-button/submit-button.component';

@Injectable({
    providedIn: 'root',
})
export class DynamicUiService {
    private serviceApiUrl: string;

    private urls = this.authFacade.urls$;
    private uiDefinitions$ = this.authFacade.uiDefinitions$;
    private uiDefinitions: any[] = [];
    private sortedUrls: Url[] = [];
    private formControls: { [key: string]: ElementRef[] } = {};

    constructor(
        private readonly http: HttpClient,
        @Inject('BASE_URL') baseUrl: string,
        private dynamicFacade: DynamicFacade,
        private authFacade: AuthFacade,
        private router: Router,
        private urlSerializer: UrlSerializer
    ) {
        this.serviceApiUrl = `${baseUrl}api/dynamic`;

        this.urls.subscribe((urls) => {
            // Sortiert nach der Länge der URL (für StartsWith vergleich)
            this.sortedUrls = urls.sort((a, b) => {
                if (a.url.length > b.url.length) {
                    return -1;
                } else if (a.url.length < b.url.length) {
                    return 1;
                } else {
                    return 0;
                }
            });
            // Für alphabetische sortierung
            // data = data.sort((a, b) =>  a.name.localeCompare(b.name));
        });
        this.uiDefinitions$.subscribe(
            (uiDefinitions) => (this.uiDefinitions = uiDefinitions)
        );
    }

    public GetUiDefinitionFromUrl(url: string): UiDefinition | null {
        const urlSplit = url.split('?')[0];
        const filteredUrl = this.sortedUrls.find(
            (urlEntry) => urlSplit.toLowerCase() === urlEntry.url.toLowerCase()
        );

        if (filteredUrl) {
            const filteredUiDefinition = this.uiDefinitions.find(
                (uiDefinition) =>
                    uiDefinition.name === filteredUrl.uiDefinitionName
            );
            if (filteredUiDefinition) {
                return filteredUiDefinition;
            }
        }
        return null;
    }

    GetComponent(uiComponent: UiComponent): any {
        if (uiComponent && uiComponent.type && uiComponent.typeName) {
            switch (uiComponent.type.toLowerCase()) {
                case 'control':
                    return this.GetControl(uiComponent);
                case 'action':
                    // do nothing
                    return null;
                default:
                    console.log('unknown UiComponent Type : ', uiComponent);
                    break;
            }
        }
    }

    GetControl(uiComponent: UiComponent): any {
        switch (uiComponent.typeName.toLowerCase()) {
            case 'label':
                return { component: LabelComponent };
            case 'labelwithtitle':
                return { component: LabelWithTitleComponent };
            case 'row':
                return { component: RowComponent };
            case 'column':
                return { component: ColumnComponent };
            case 'tab':
                return { component: TabComponent };
            case 'link':
                return { component: LinkComponent };
            case 'form':
                return { component: FormComponent };
            case 'submitbutton':
                return { component: SubmitButtonComponent };
            case 'input':
                return { component: InputComponent };
            case 'fileselector':
                return { component: FileSelectorComponent };
            case 'checkbox':
                return { component: CheckboxComponent };
            case 'dropdown':
                return { component: DropDownComponent };
            case 'table':
                return { component: TableComponent };
            case 'tree':
                return { component: TreeComponent };
            case 'button':
                return { component: ButtonComponent };
            case 'card':
                return { component: CardComponent };
            case 'grid':
                return { component: GridComponent };
            case 'griditem':
                return { component: GridItemComponent };
            case 'title':
                return { component: TitleComponent };
            case 'expansionpanel':
                return { component: ExpansionPanelComponent };
            case 'accordion':
                return { component: AccordionComponent };
            case 'divider':
                return { component: DividerComponent };
            case 'pdfviewer':
                return { component: PdfViewerComponent };
            default:
                console.log('unknown UiComponent TypeName : ', uiComponent);
                return null;
        }
    }

    ExecuteActionFromName(actionName: string): Observable<UiAction> {
        return this.dynamicFacade.GetAction(actionName).pipe(
            first(),
            mergeMap((action) => {
                console.log('ExecuteActionFromName', actionName);
                return this.ExecuteAction(action);
            })
        );
    }

    InitService() {
        this.formControls = {};
        //TODO: Implementieren, dass alle SubScriptions, die von Actions definiert wurden, wieder entfernt werden.
    }

    private ExecuteAction(uiAction: UiAction): Observable<UiAction> {
        switch (uiAction?.typeName?.toLowerCase()) {
            case 'executestoredprocedure': {
                this.dynamicFacade.ExecuteStoredProcedure(uiAction);
                return of(uiAction);
            }
            case 'copydata': {
                this.CopyData(uiAction);
                return of(uiAction);
            }
            case 'staticvaluetodata': {
                this.StaticValueToData(uiAction);
                return of(uiAction);
            }
            case 'navigatetourl': {
                this.NavigateToUrl(uiAction);
                return of(uiAction);
            }
            case 'removedata': {
                this.RemoveData(uiAction);
                return of(uiAction);
            }
            case 'generatepdf': {
                this.dynamicFacade.GeneratePDF(uiAction);
                return of(uiAction);
            }
            case 'sendmail': {
                this.dynamicFacade.SendMail(uiAction);
                return of(uiAction);
            }
            default:
                console.log('default', uiAction);
                return of(uiAction);
        }
    }

    private CopyData(uiAction: UiAction) {
        if (uiAction.config) {
            if (
                uiAction.config.sourceStoreData &&
                uiAction.config.targetStoreData
            ) {
                var addToTarget = uiAction.config.addToTarget ?? false;
                var addToTargetIfNotExists =
                    uiAction.config.addToTargetIfNotExists ?? false;
                if (addToTargetIfNotExists == true) {
                    this.AddValueIfNotExists(
                        uiAction.config.targetStoreData,
                        this.GetValue(uiAction.config.sourceStoreData)
                    );
                } else if (addToTarget == true) {
                    this.AddValue(
                        uiAction.config.targetStoreData,
                        this.GetValue(uiAction.config.sourceStoreData)
                    );
                } else {
                    this.SetValue(
                        uiAction.config.targetStoreData,
                        this.GetValue(uiAction.config.sourceStoreData)
                    );
                }
            }
        }
    }

    private RemoveData(uiAction: UiAction) {
        if (uiAction.config) {
            if (uiAction.config.storeData) {
                this.RemoveValue(uiAction.config.storeData);
            }
        }
    }

    private StaticValueToData(uiAction: UiAction) {
        if (uiAction.config) {
            if (
                uiAction.config.staticValue &&
                uiAction.config.targetStoreData
            ) {
                const json = JSON.parse(uiAction.config.staticValue ?? '{}');
                this.SetValue(uiAction.config.targetStoreData, json);
            }
        }
    }

    private NavigateToUrl(uiAction: UiAction) {
        if (uiAction.config) {
            var url = uiAction.config.url;
            var inNewWindow = uiAction.config.inNewWindow ?? false;
            if (inNewWindow) {
                if (
                    url.toLowerCase().startsWith('http://') ||
                    url.toLowerCase().startsWith('https://')
                ) {
                    const completeUrl = new URL(url);
                    if (uiAction.config.inputParameters) {
                        for (const element of uiAction.config.inputParameters) {
                            if (element.staticValue) {
                                completeUrl.searchParams.append(
                                    element.parameterName,
                                    element.staticValue
                                );
                            } else {
                                completeUrl.searchParams.append(
                                    element.parameterName,
                                    this.GetValue(element.sourceStoreData)
                                );
                            }
                        }
                    }
                    window.open(completeUrl.toString(), '_blank');
                } else {
                    var urlTree = this.router.createUrlTree([url]);
                    if (uiAction.config.inputParameters) {
                        let params: { [key: string]: string } = {};
                        for (const element of uiAction.config.inputParameters) {
                            if (element.staticValue) {
                                params[element.parameterName] =
                                    element.staticValue;
                            } else {
                                params[element.parameterName] = this.GetValue(
                                    element.sourceStoreData
                                );
                            }
                        }
                        urlTree = this.router.createUrlTree([url], {
                            queryParams: params,
                        });
                    }
                    const completeUrl = this.router.serializeUrl(urlTree);
                    window.open(completeUrl, '_blank');
                }
            } else {
                var urlTree = this.router.createUrlTree([url]);
                if (uiAction.config.inputParameters) {
                    let params: { [key: string]: string } = {};
                    for (const element of uiAction.config.inputParameters) {
                        if (element.staticValue) {
                            params[element.parameterName] = element.staticValue;
                        } else {
                            params[element.parameterName] = this.GetValue(
                                element.sourceStoreData
                            );
                        }
                    }
                    this.router.navigate([url], { queryParams: params });
                } else {
                    this.router.navigate([url]);
                }
            }
        }
    }

    ActionExecuteStoredProcedure(
        uiAction: UiAction
    ): Observable<ExecuteSpResponse> {
        if (uiAction.config) {
            if (uiAction.config.storedProcedureName) {
                return this.ExecuteSp(
                    uiAction.config.storedProcedureName,
                    uiAction.config.inputParameters,
                    uiAction.config.withoutPermission ?? false
                );
            } else return of(null);
        } else return of(null);
    }

    ActionGeneratePDF(
        uiAction: UiAction
    ): Observable<GeneratePDFResponse> {
        if (uiAction.config) {
            if (uiAction.config.templateName) {
                return this.GeneratePDF(
                    this.GetValue(uiAction.config.templateName)?.toString() ?? "",
                    this.GetValue(uiAction.config.parameter1)?.toString() ?? "",
                    this.GetValue(uiAction.config.parameter2)?.toString() ?? "",
                    this.GetValue(uiAction.config.parameter3)?.toString() ?? "",
                    this.GetValue(uiAction.config.parameter4)?.toString() ?? "",
                    this.GetValue(uiAction.config.parameter5)?.toString() ?? ""
                );
            } else return of(null);
        } else return of(null);
    }

    ActionSendMail(
        uiAction: UiAction
    ): Observable<SendMailResponse> {
        if (uiAction.config) {
            if (uiAction.config.recipients) {
                return this.SendMail(
                    this.GetValue(uiAction.config.sender)?.toString() ?? "",
                    this.GetValue(uiAction.config.recipients)?.toString() ?? "",
                    this.GetValue(uiAction.config.subject)?.toString() ?? "",
                    this.GetValue(uiAction.config.mailbody)?.toString() ?? "",
                    this.GetValue(uiAction.config.viaSMTP)?.toString() ?? "0"
                );
            } else return of(null);
        } else return of(null);
    }

    private ExecuteSp(
        spName: string,
        parameters: SpInputParameter2[],
        withoutPermission: boolean
    ): Observable<ExecuteSpResponse> {
        const headers = { 'content-type': 'application/json' };
        const body = new ExecuteSpRequest(spName, withoutPermission);
        if (!withoutPermission) {
            body.inputParameters.push(
                new SpRequestBodyInputParameter(
                    'currentPersonId',
                    '',
                    'specialValue.currentPerson.id'
                )
            );
            body.inputParameters.push(
                new SpRequestBodyInputParameter(
                    'currentVereinId',
                    '',
                    this.GetValue('session.currentClub.id')
                )
            );
            body.inputParameters.push(
                new SpRequestBodyInputParameter(
                    'currentAnlassId',
                    '',
                    this.GetValue('session.currentAnlass.Id')
                )
            );
        }
        for (const element of parameters) {
            body.inputParameters.push(
                new SpRequestBodyInputParameter(
                    element.parameterName,
                    element.sqlDataType,
                    this.GetValueFromSqlParameter(element) ?? ''
                )
            );
        }
        return this.http.post<ExecuteSpResponse>(
            this.serviceApiUrl + '/executesp',
            body,
            { headers: headers }
        );
    }

    private GeneratePDF(
        templateName: string,
        parameter1: string = "",
        parameter2: string = "",
        parameter3: string = "",
        parameter4: string = "",
        parameter5: string = ""
    ): Observable<GeneratePDFResponse> {
        const headers = { 'content-type': 'application/json' };
        const body = new GeneratePDFRequest(templateName, parameter1, parameter2, parameter3, parameter4, parameter5);
        return this.http.post<GeneratePDFResponse>(
            this.serviceApiUrl + '/generatepdf',
            body,
            { headers: headers }
        );
    }

    private SendMail(
        sender: string,
        recipients: string[],
        subject: string,
        mailbody: string,
        viaSMTP: string
    ): Observable<SendMailResponse> {
        const headers = { 'content-type': 'application/json' };
        const body = new SendMailRequest(sender, recipients, subject, mailbody, viaSMTP);
        return this.http.post<SendMailResponse>(
            this.serviceApiUrl + '/sendmail',
            body,
            { headers: headers }
        );
    }

    private GetValueFromSqlParameter(parameter: SpInputParameter2): string {
        if (parameter.staticValue) {
            return parameter.staticValue as string;
        } else {
            let [store, storeDataName, storeDataPath] =
                ConvertStoreDataToElements(parameter.sourceStoreData);
            if (store.toLowerCase() === 'specialvalue')
                return parameter.sourceStoreData;
            let value: string;
            this.dynamicFacade
                .GetData(store, storeDataName, storeDataPath)
                .pipe(first())
                .subscribe((c) => (value = c));
            return value;
        }
    }

    GetValue(sourceStoreDataName: string): any {
        let value: string = "";
        if (sourceStoreDataName) {
            this.GetValueAsObservable(sourceStoreDataName)
                .pipe(first())
                .subscribe((c) => (value = c));
        }
        return value;
    }

    GetValueAsObservable(sourceStoreDataName: string): Observable<any> {
        let [store, storeDataName, storeDataPath] =
            ConvertStoreDataToElements(sourceStoreDataName);
        return this.dynamicFacade.GetData(store, storeDataName, storeDataPath);
    }

    SetValue(targetStoreDataName: string, value: any) {
        let [store, storeDataName, storeDataPath] =
            ConvertStoreDataToElements(targetStoreDataName);
        this.dynamicFacade.SetData(store, storeDataName, storeDataPath, value);
    }

    RemoveValue(targetStoreDataName: string) {
        let [store, storeDataName, storeDataPath] =
            ConvertStoreDataToElements(targetStoreDataName);
        this.dynamicFacade.RemoveData(store, storeDataName, storeDataPath);
    }

    AddValue(targetStoreDataName: string, value: any[]) {
        var oldValue = (this.GetValue(targetStoreDataName) as any[]) ?? [];
        var newValue = [...oldValue, ...value];
        this.SetValue(targetStoreDataName, newValue);
    }

    AddValueIfNotExists(targetStoreDataName: string, value: any[]) {
        const merge = (
            a: any[],
            b: any[],
            predicate = (a: any[], b: any[]) => isEqual(a, b)
        ) => {
            const c = [...a]; // copy to avoid side effects
            // add all items from B to copy C if they're not already present
            b.forEach((bItem) =>
                c.some((cItem) => predicate(bItem, cItem))
                    ? null
                    : c.push(bItem)
            );
            return c;
        };

        var oldValue = (this.GetValue(targetStoreDataName) as any[]) ?? [];
        var newValue = merge(oldValue, value);
        this.SetValue(targetStoreDataName, newValue);
    }

    public AddControlToFormControls(formGroupName: string, control: ElementRef) {
        this.formControls[formGroupName] = [...this.GetFormControls(formGroupName), control];
    }

    public GetFormControls(formGroupName: string): ElementRef[] {
        if (!this.formControls[formGroupName]) {
            this.formControls[formGroupName] = [];
        }
        return this.formControls[formGroupName];
    }

    public IsFormValid(formGroupName: string): boolean {
        let controls = this.GetFormControls(formGroupName);
        return controls.every((control) => control?.nativeElement?.validity.valid);
    }

}
